<template>
    <div>
        <helpdesk :current-user="currentUser" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Helpdesk from '@/views/commons/components/helpdesk/Helpdesk.vue'
export default {
    components:{ Helpdesk },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
        }),
    },
}
</script>